import 'react-toastify/dist/ReactToastify.css';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
// routing
import Routes from 'routes';
import socketServcies from 'socketService';
// defaultTheme
import themes from 'themes';
import PathContext from 'utils/constext/PathContext';
import ToasterContext from 'utils/constext/ToasterContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// ==============================|| APP ||============================== //
const options = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const App = () => {
  const customization = useSelector((state) => state.customization);
  const [showToaster, setShowToaster] = useState();
  const [path, setPath] = useState('/jobs/commercial');
  const fireToasterHandler = (value, message) => {
    if (value) {
      toast.success(message, options);
    } else {
      toast.error(message, options);
    }

    setShowToaster(value);
  };

  useEffect(() => {
    socketServcies.initializeSocket();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ToasterContext.Provider
        value={{
          isSuccess: showToaster,
          fireToasterHandler: fireToasterHandler,
        }}
      >
        <PathContext.Provider
          value={{
            path,
            setPath,
          }}
        >
          <ThemeProvider theme={themes(customization)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              <NavigationScroll>
                <ToastContainer />
                <Routes />
              </NavigationScroll>
            </LocalizationProvider>
          </ThemeProvider>
        </PathContext.Provider>
      </ToasterContext.Provider>
    </StyledEngineProvider>
  );
};

export default App;
