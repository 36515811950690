// assets
// import { IconShadow, IconWindmill, IconBriefcase, IconUser, IconChat } from '@tabler/icons';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
// ==============================|| UTILITIES MENU ITEMS ||============================== //
const superAdminStatus = JSON.parse(
  window.localStorage.getItem('isSuperAdminStatus')
);
// if (!user) {
//   window.location.href = `${window.location.origin}/login`;
// }

let permissions = localStorage.getItem('permissions');
const permissionsArray = permissions?.length > 0 ? permissions.split(',') : [];
// const superAdminStatus = user?.isSuperAdmin;
const routesWithPermissions = {
  id: 'routesWithPermissions',
  // title: 'Utilities',
  type: 'group',
  children: [],
};
// let tempArray = [...routesWithPermissions.children];
if (permissionsArray.includes('show dashboard')) {
  routesWithPermissions.children.push({
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: HomeOutlinedIcon,
    breadcrumbs: false,
  });
}

if (permissionsArray.includes('show jobs')) {
  routesWithPermissions.children.push({
    id: 'icons',
    title: 'Jobs',
    type: 'collapse',
    icon: BusinessCenterOutlinedIcon,
    children: [
      {
        id: 'commercial',
        title: 'Commercial',
        type: 'item',
        url: '/jobs/commercial',
        breadcrumbs: false,
      },
      {
        id: 'fleet',
        title: 'Fleet',
        type: 'item',
        url: '/jobs/fleet',
        breadcrumbs: false,
      },
    ],
  });
}
// This is to be uncommented in near future, don't ever try to remove this ...
if (permissionsArray.includes('chat')) {
  routesWithPermissions.children.push({
    id: '1',
    title: 'Chat',
    type: 'item',
    url: '/chat',
    icon: ChatOutlinedIcon,
    breadcrumbs: false,
    chip: {
      label: 'AA',
      color: 'danger',
    },
  });
}
if (permissionsArray.includes('show users')) {
  routesWithPermissions.children.push({
    id: 'user',
    title: 'User',
    type: 'item',
    url: '/users',
    icon: PersonOutlineOutlinedIcon,
    breadcrumbs: false,
  });
}

if (permissionsArray.includes('show admins')) {
  routesWithPermissions.children.push({
    id: 'admin-listing',
    title: 'Admins',
    type: 'item',
    url: '/admins',
    icon: SupervisorAccountOutlinedIcon,
    breadcrumbs: false,
  });
}

if (permissionsArray.includes('show companies')) {
  routesWithPermissions.children.push({
    id: '2',
    title: 'Companies',
    type: 'item',
    url: '/companies',
    icon: BusinessOutlinedIcon,
    breadcrumbs: false,
  });
}

if (permissionsArray.includes('show coupons')) {
  routesWithPermissions.children.push({
    id: '3',
    title: 'Coupons',
    type: 'item',
    url: '/coupons',
    icon: ConfirmationNumberOutlinedIcon,
    breadcrumbs: false,
  });
}

if (permissionsArray.includes('show discounts')) {
  routesWithPermissions.children.push({
    id: '4',
    title: 'Discount',
    type: 'item',
    url: '/discount',
    icon: DiscountOutlinedIcon,
    breadcrumbs: false,
  });
}
if (
  permissionsArray.includes('show categories') ||
  permissionsArray.includes('show costs') ||
  permissionsArray.includes('show damages') ||
  permissionsArray.includes('show rating criterias') ||
  permissionsArray.includes('show rating criteria options')
) {
  let obj = {
    id: 'icons',
    title: 'System Setup',
    type: 'collapse',
    icon: SettingsOutlinedIcon,
    children: [],
  };
  if (permissionsArray.includes('show categories')) {
    obj.children.push({
      id: 'category',
      title: 'Category',
      type: 'item',
      url: '/category',
      breadcrumbs: false,
    });
  }
  if (permissionsArray.includes('show costs')) {
    obj.children.push({
      id: 'cost',
      title: 'Cost',
      type: 'item',
      url: '/cost',
      breadcrumbs: false,
    });
  }
  if (permissionsArray.includes('show damages')) {
    obj.children.push({
      id: 'damages',
      title: 'Damages',
      type: 'item',
      url: '/damages',
      breadcrumbs: false,
    });
  }
  if (permissionsArray.includes('show rating criterias')) {
    obj.children.push({
      id: 'criteria',
      title: 'Rating Criteria',
      type: 'item',
      url: '/criteria',
      breadcrumbs: false,
    });
  }
  if (permissionsArray.includes('show rating criteria options')) {
    obj.children.push({
      id: 'criteriaOption',
      title: 'Rating Criteria Option',
      type: 'item',
      url: '/criteriaOption',
      breadcrumbs: false,
    });
  }

  routesWithPermissions.children.push(obj);
}
if (permissionsArray.includes('show payout reports')) {
  routesWithPermissions.children.push({
    id: 'reports',
    title: 'Reports',
    type: 'item',
    url: '/reports',
    icon: ReceiptIcon,
    breadcrumbs: false,
  });
}
const utilities = {
  id: 'utilities',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: HomeOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'icons',
      title: 'Jobs',
      type: 'collapse',
      icon: BusinessCenterOutlinedIcon,
      children: [
        {
          id: 'commercial',
          title: 'Retail',
          type: 'item',
          url: '/jobs/commercial',
          breadcrumbs: false,
        },
        {
          id: 'fleet',
          title: 'Fleet',
          type: 'item',
          url: '/jobs/fleet',
          breadcrumbs: false,
        },
        {
          id: 'pave',
          title: 'Pave',
          type: 'item',
          url: '/jobs/pave',
          breadcrumbs: false,
        },
      ],
    },
    {
      id: 'user',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: PersonOutlineOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: '1',
      title: 'Chat',
      type: 'item',
      url: '/chat',
      icon: ChatOutlinedIcon,
      breadcrumbs: false,
      chip: {
        label: 'AA',
        color: 'danger',
      },
    },
    {
      id: 'admin-listing',
      title: 'Admins',
      type: 'item',
      url: '/admins',
      icon: SupervisorAccountOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: '2',
      title: 'Companies',
      type: 'item',
      url: '/companies',
      icon: BusinessOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: '3',
      title: 'Coupons',
      type: 'item',
      url: '/coupons',
      icon: ConfirmationNumberOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: '4',
      title: 'Discount',
      type: 'item',
      url: '/discount',
      icon: DiscountOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'icons',
      title: 'System Setup',
      type: 'collapse',
      icon: SettingsOutlinedIcon,
      children: [
        {
          id: 'category',
          title: 'Category',
          type: 'item',
          url: '/category',
          breadcrumbs: false,
        },
        {
          id: 'cost',
          title: 'Cost',
          type: 'item',
          url: '/cost',
          breadcrumbs: false,
        },
        {
          id: 'damages',
          title: 'Damages',
          type: 'item',
          url: '/damages',
          breadcrumbs: false,
        },
        {
          id: 'criteria',
          title: 'Rating Criteria',
          type: 'item',
          url: '/criteria',
          breadcrumbs: false,
        },
        {
          id: 'criteriaOption',
          title: 'Rating Criteria Option',
          type: 'item',
          url: '/criteriaOption',
          breadcrumbs: false,
        },
      ],
    },
    {
      id: 'icons',
      title: 'Roles Setup',
      type: 'collapse',
      icon: SupervisorAccountIcon,
      children: [
        {
          id: 'roles',
          title: 'Roles',
          type: 'item',
          url: '/roles',
          breadcrumbs: false,
        },
        {
          id: 'permissions',
          title: 'Permissions',
          type: 'item',
          url: '/permissions',
          breadcrumbs: false,
        },
      ],
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'collapse',
      icon: ReceiptIcon,
      children: [
        {
          id: 'reports',
          title: 'Reports',
          type: 'item',
          url: '/reports',
          breadcrumbs: false,
        },
        {
          id: 'reportsPave',
          title: 'Pave',
          type: 'item',
          url: '/reports/pave',
          breadcrumbs: false,
        },
      ],
    },
  ],
};

let navigations;
if (superAdminStatus === true) {
  navigations = utilities;
} else {
  navigations = routesWithPermissions;
}

export default navigations;
