// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('views/dashboard/Default'))
);

// utilities routing
const JobDetail = Loadable(lazy(() => import('views/jobs/job-detail')));
const PaveDetails = Loadable(
  lazy(() => import('views/jobs/job-detail/PaveDetails'))
);
const BulkJobDetail = Loadable(
  lazy(() => import('views/jobs/bulk-job/BulkJobListing'))
);
const JobsList = Loadable(lazy(() => import('../views/jobs/index')));
const AddJob = Loadable(lazy(() => import('views/jobs/add-job')));
const ChatContainer = Loadable(lazy(() => import('views/chat')));
const AdminListing = Loadable(lazy(() => import('views/admins')));
const AddAdmin = Loadable(lazy(() => import('views/admins/AddAdmin')));
const CompaniesList = Loadable(lazy(() => import('views/companies')));
const AddCompany = Loadable(lazy(() => import('views/companies/AddCompany')));
const CouponList = Loadable(lazy(() => import('views/coupons')));
const AddCoupon = Loadable(lazy(() => import('views/coupons/AddCoupon')));
const DiscountList = Loadable(lazy(() => import('views/discount')));
const AddDiscount = Loadable(lazy(() => import('views/discount/AddDiscount')));
const CriteriaList = Loadable(lazy(() => import('views/criterias')));
const AddCriteria = Loadable(lazy(() => import('views/criterias/AddCriteria')));
const CriteriaOption = Loadable(lazy(() => import('views/criteria-option')));
const AddCriteriaOption = Loadable(
  lazy(() => import('views/criteria-option/AddCriteriaOption'))
);
const DamagesList = Loadable(lazy(() => import('views/Damages')));
const CostList = Loadable(lazy(() => import('views/cost')));
const CategoryList = Loadable(lazy(() => import('views/category')));
const AddCategory = Loadable(lazy(() => import('views/category/AddCategory')));
const SubCategory = Loadable(lazy(() => import('views/sub-category')));
const RolesList = Loadable(lazy(() => import('views/roles')));
const PermissionsList = Loadable(lazy(() => import('views/permissions')));
const UsersList = Loadable(lazy(() => import('views/users/UsersScreen')));
const UserDetail = Loadable(lazy(() => import('views/users/UserDetail')));
const ReportsList = Loadable(lazy(() => import('views/reports')));
const PaveReport = Loadable(
  lazy(() => import('views/reports/pave/PaveReportScreen'))
);
const ExtraRoute = Loadable(lazy(() => import('views/extra')));
// sample page routing

// ==============================|| MAIN ROUTING ||============================== //
let permissions = localStorage.getItem('permissions');
const permissionsArray = permissions?.length > 0 ? permissions?.split(',') : [];
const superAdminStatus = JSON.parse(
  window.localStorage.getItem('isSuperAdminStatus')
);
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '*',
      children: [
        {
          path: '*',
          element: <ExtraRoute />,
        },
      ],
    },
  ],
};
if (permissionsArray.includes('show dashboard')) {
  MainRoutes.children.push(
    {
      path: '/',
      element: <DashboardDefault />,
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '/dashboard',
          element: <DashboardDefault />,
        },
      ],
    }
  );
}
if (superAdminStatus) {
  MainRoutes.children.push(
    {
      path: 'roles',
      children: [
        {
          path: '/roles',
          element: <RolesList />,
        },
      ],
    },
    {
      path: 'permissions',
      children: [
        {
          path: '/permissions',
          element: <PermissionsList />,
        },
      ],
    }
  );
}
if (permissionsArray.includes('show jobs')) {
  let JobsRoute = {
    path: 'jobs',
    children: [
      {
        path: '/jobs/commercial',
        element: <JobsList />,
      },
      {
        path: '/jobs/fleet',
        element: <JobsList />,
      },
      {
        path: '/jobs/pave',
        element: <JobsList />,
      },
    ],
  };
  if (permissionsArray.includes('show jobs')) {
    JobsRoute.children.push({
      path: '/jobs/job-detail/:id',
      children: [
        {
          path: '/jobs/job-detail/:id',
          element: <JobDetail />,
        },
        {
          path: '/jobs/job-detail/:id/pave-details',
          element: <PaveDetails />,
        },
      ],
    });
  }
  if (permissionsArray.includes('add job')) {
    JobsRoute.children.push({
      path: '/jobs/addjob',
      element: <AddJob />,
    });
  }
  // if (permissionsArray.includes('review job')) {
  //   JobsRoute.children.push({
  //     path: '/jobs/job-details/:id',
  //     element: <JobEditabl />
  //   });
  // }
  MainRoutes.children.push(JobsRoute);
  MainRoutes.children.push({
    path: 'bulk-jobs-listing',
    children: [
      {
        path: '/bulk-jobs-listing',
        element: <BulkJobDetail />,
      },
    ],
  });
}
if (permissionsArray.includes('chat')) {
  MainRoutes.children.push({
    path: 'chat',
    children: [
      {
        path: '/chat',
        element: <ChatContainer />,
      },
    ],
  });
}
if (permissionsArray.includes('show users')) {
  MainRoutes.children.push({
    path: 'users',
    children: [
      {
        path: '/users',
        element: <UsersList />,
      },
      {
        path: '/users/detail/:id',
        element: <UserDetail />,
      },
    ],
  });
}
if (permissionsArray.includes('show coupons')) {
  MainRoutes.children.push({
    path: 'coupons',
    children: [
      {
        path: '/coupons',
        element: <CouponList />,
      },
      {
        path: '/coupons/addCoupon',
        element: <AddCoupon />,
      },
    ],
  });
}
if (permissionsArray.includes('show discounts')) {
  MainRoutes.children.push({
    path: 'discount',
    children: [
      {
        path: '/discount',
        element: <DiscountList />,
      },
      {
        path: '/discount/AddDiscount',
        element: <AddDiscount />,
      },
    ],
  });
}

if (permissionsArray.includes('show admins')) {
  let AdminsRoutes = {
    path: 'admins',
    children: [
      {
        path: '/admins',
        element: <AdminListing />,
      },
    ],
  };
  if (permissionsArray.includes('add admin')) {
    AdminsRoutes.children.push({
      path: '/admins/addAdmin',
      element: <AddAdmin />,
    });
  }
  MainRoutes.children.push(AdminsRoutes);
}

// if (userObj?.user?.companyId) {
//   permittedRoutes.push(routes.find((el) => el.path === '/company/jobs'));
//   permittedRoutes.push(routes.find((el) => el.path === '/pdfViewer'));
//   permittedRoutes.push(routes.find((el) => el.path === '/jobs/detail'));
// }

if (permissionsArray.includes('show companies')) {
  let companyRoutes = {
    path: 'companies',
    children: [
      {
        path: '/companies',
        element: <CompaniesList />,
      },
    ],
  };
  if (permissionsArray.includes('add company')) {
    companyRoutes.children.push({
      path: '/companies/addComapany',
      element: <AddCompany />,
    });
  }
  MainRoutes.children.push(companyRoutes);
}

if (permissionsArray.includes('show categories')) {
  let CategoriesRoutes = {
    path: 'category',
    children: [
      {
        path: '/category',
        element: <CategoryList />,
      },

      {
        path: '/category/subCategory',
        element: <SubCategory />,
      },
    ],
  };
  if (permissionsArray.includes('add category')) {
    CategoriesRoutes.children.push({
      path: '/category/addCategory',
      element: <AddCategory />,
    });
  }
  MainRoutes.children.push(CategoriesRoutes);
}

if (permissionsArray.includes('show costs')) {
  MainRoutes.children.push({
    path: 'cost',
    children: [
      {
        path: '/cost',
        element: <CostList />,
      },
    ],
  });
}

if (permissionsArray.includes('show damages')) {
  MainRoutes.children.push({
    path: 'damages',
    children: [
      {
        path: '/damages',
        element: <DamagesList />,
      },
    ],
  });
}
if (permissionsArray.includes('show payout reports')) {
  MainRoutes.children.push({
    path: 'reports',
    children: [
      {
        path: '/reports',
        element: <ReportsList />,
      },
      {
        path: '/reports/pave',
        element: <PaveReport />,
      },
    ],
  });
}

if (permissionsArray.includes('show rating criterias')) {
  let CriteriaRoutes = {
    path: 'criteria',
    children: [
      {
        path: '/criteria',
        element: <CriteriaList />,
      },
    ],
  };
  if (permissionsArray.includes('add rating criteria')) {
    CriteriaRoutes.children.push({
      path: '/criteria/addCriteria',
      element: <AddCriteria />,
    });
  }
  MainRoutes.children.push(CriteriaRoutes);
}

if (permissionsArray.includes('show rating criteria options')) {
  let CriteriaOptionsRoutes = {
    path: 'criteriaOption',
    children: [
      {
        path: '/criteriaOption',
        element: <CriteriaOption />,
      },
    ],
  };
  if (permissionsArray.includes('add rating criteria option')) {
    CriteriaOptionsRoutes.children.push({
      path: '/criteriaOption/addCriteriaOption',
      element: <AddCriteriaOption />,
    });
  }
  MainRoutes.children.push(CriteriaOptionsRoutes);
}
export default MainRoutes;
