import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';

const initialState = {
  reviewedJobsList: [],
  paveReportError: null,
  paveReportLoading: false,
};

const slice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    getReviewedJobsList(state, action) {
      state.reviewedJobsList = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaveReport.pending, (state, action) => {
      state.paveReportLoading = true;
    });
    builder.addCase(fetchPaveReport.fulfilled, (state, action) => {
      state.paveReportLoading = false;
      state.paveReportError = null;
    });
    builder.addCase(fetchPaveReport.rejected, (state, action) => {
      state.paveReportLoading = false;
      state.paveReportError = action.payload;
    });
  },
});

export function getReviewedJobsAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `jobs/get-reviewed-jobs-by-admin`
      );
      dispatch(slice.actions.getReviewedJobsList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateJobsReportsStatusAction(data, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`jobs/admin-payout-to-r8tr`, {
        data,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

// export function updateCostAction(id, paramsData, handleClose) {
//   return async () => {
//     try {
//       const response = await axiosInstance.patch(`costs/?id=${id}`, {
//         multiplier: paramsData.multiplier,
//         minimumCost: paramsData.minimumCost,
//         priceRangeLow: paramsData.priceRangeLow,
//         priceRangeHigh: paramsData.priceRangeHigh
//       });
//       successMessage(response?.data?.message);
//       handleClose();
//     } catch (error) {
//       errorMessage(error?.response?.data?.message);
//       handleClose();
//     }
//   };
// }

export const fetchPaveReport = createAsyncThunk(
  'job/getPaveReportAction',
  async ({ dateFrom, dateTo } = {}, thunkAPI) => {
    try {
      const getParams = () => {
        if (!dateFrom && !dateTo) {
          return {};
        }
        if (dateFrom && !dateTo) {
          return { dateFrom };
        }
        if (!dateFrom && dateTo) {
          return { dateTo };
        }
        return { dateFrom, dateTo };
      };

      const response = await axiosInstance.get(`pave/report/summary/file`, {
        params: getParams(),
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export default slice.reducer;
